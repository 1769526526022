import { observable } from 'mobx'
import { Model, Store } from 'store/Base';
import { OutShipment } from './OutShipment';


export class PackStationBox extends Model {
  static backendResourceName = 'pack_station_box'

  @observable id = null
  @observable serialNumber = ''
  @observable isFree = true
  @observable deleted = false

  relations() {
    return {
      outShipment: OutShipment,
    }
  }
}

export class PackStationBoxStore extends Store {
  static backendResourceName = 'pack_station_box'
  Model = PackStationBox
}
